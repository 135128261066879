import { ActivateDealershipModal } from 'overview/components/ActivateDealershipModal';
import {
  OverviewHeader,
  OverviewHeaderLeft,
} from 'overview/components/OverviewHeader';
import {
  changeDealershipStatus,
  getAllDealerships,
} from 'overview/store/overviewActions';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ButtonPrimary } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { ContentWrapper, Spacer, WhiteBox } from 'shared/components/Layout';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';
import MetaTitle from 'shared/components/MetaTitle';
import { Table, Tbody, Td, Th, Thead, Tr } from 'shared/components/Table';
import { ToggleCheckbox } from 'shared/components/ToggleCheckbox';
import { H1, H2 } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import { anzac, lynch } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Dealership } from 'shared/interface/Dealership';
import UtilService from 'shared/services/util.service';
import { RootState } from 'store';

export const DealershipAccountPage = () => {
  const dispatch = useAppDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isActiveList, setIsActive] = useState<
    { id: string; value: boolean }[]
  >([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmText, setConfirmText] = useState('');
  const [selectedDealership, setselectedDealership] = useState({
    id: '',
    value: false,
  });

  const dealerships: Dealership[] | null = useSelector(
    (state: RootState) => state.overviewReducer.dealerships
  );

  const handleActiveChange = (id: string, isActive: boolean) => {
    setIsActive(
      isActiveList.map((item) =>
        item.id === id
          ? {
              ...item,
              value: isActive,
            }
          : {
              ...item,
            }
      )
    );

    setOpenConfirmModal(true);
    setConfirmText(isActive ? 'Activate' : 'Deactivate');
    setselectedDealership({ id, value: isActive });
  };

  const loadDealerships = () => {
    dispatch(getAllDealerships(setLoading, () => setLoading(false)));
  };
  useEffect(() => {
    loadDealerships();
  }, []);

  useEffect(() => {
    if (dealerships && dealerships.length > 0) {
      setIsActive(
        dealerships.map((item) => {
          return { id: item.id, value: item.isActive };
        })
      );
    }
  }, [dealerships]);

  const revertDealershipSelection = () => {
    setIsActive(
      isActiveList.map((item) =>
        item.id === selectedDealership.id
          ? {
              ...item,
              value: !selectedDealership.value,
            }
          : {
              ...item,
            }
      )
    );
  };

  const onChangeStatusSuccess = () => {
    toast.success(`Dealership successfully ${confirmText.toLowerCase()}d.`);
    setOpenConfirmModal(false);
  };

  const onChangeStatusFail = () => {
    toast.error(`Dealership failed to ${confirmText.toLowerCase()}.`);
    setOpenConfirmModal(false);
    revertDealershipSelection();
  };

  const handleChangeDealershipStatus = () => {
    dispatch(
      changeDealershipStatus(
        {
          dealershipId: selectedDealership.id,
          isActive: selectedDealership.value,
        },
        onChangeStatusSuccess,
        onChangeStatusFail
      )
    );
  };

  const closeConfirmModal = () => {
    setOpenConfirmModal(false);
    revertDealershipSelection();
  };

  return (
    <>
      <MetaTitle title="Dealerships | Plato Connect" />

      <OverviewHeader>
        <OverviewHeaderLeft>
          <H1>Dealerships</H1>
          <Wrapper flex middle>
            <ButtonPrimary type="button" onClick={() => setOpenModal(true)}>
              New Dealership
            </ButtonPrimary>
          </Wrapper>
        </OverviewHeaderLeft>
      </OverviewHeader>
      <Spacer h="16px" />

      <ContentWrapper>
        <H2>Dealership Account</H2>
        <Spacer h="16px" />
        <WhiteBox>
          <Table>
            <Thead>
              <Tr>
                <Th>Dealership</Th>
                <Th>Account Number</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            {loading && (
              <LoaderFullScreen top={64} left={244} flex center middle>
                <Loader size={120} platoLogo={1} />
              </LoaderFullScreen>
            )}
            {!loading && dealerships && dealerships.length > 0 && (
              <Tbody>
                {dealerships
                  .sort((a, b) => UtilService.sortBy(a.name, b.name))
                  .map((dealership: Dealership) => (
                    <Tr key={dealership.id}>
                      <Td>{dealership.name}</Td>
                      <Td>{dealership.accountNumber}</Td>
                      <Td>
                        <ToggleCheckbox
                          checked={
                            isActiveList.find((x) => x.id === dealership.id)
                              ?.value
                          }
                          onChange={(event) =>
                            handleActiveChange(
                              dealership.id,
                              event.target.checked
                            )
                          }
                          id={dealership.id}
                        />
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            )}
            {!loading && dealerships && dealerships.length === 0 && (
              <Tbody>
                <Tr>
                  <Th colSpan={3}>No Dealerships.</Th>
                </Tr>
              </Tbody>
            )}
          </Table>
        </WhiteBox>
      </ContentWrapper>

      <ActivateDealershipModal
        opened={openModal}
        close={() => setOpenModal(false)}
        loadDealerships={loadDealerships}
      />

      <ConfirmationModal
        opened={openConfirmModal}
        title="Are you sure?"
        message={`Are you sure you want to ${confirmText.toLowerCase()} this dealership account?`}
        buttonText={confirmText}
        confirm={handleChangeDealershipStatus}
        cancel={closeConfirmModal}
        confirmBgColor={selectedDealership.value ? lynch : anzac}
      />
    </>
  );
};
